import React, {useEffect, useState} from "react"
import Layout from "../components/layout";
import {isMobile} from "react-device-detect";
import request from "graphql-request";
import loadable from "@loadable/component";
import CardComponent from "../components/utilityComponents/CardComponent";
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
    Col,
    CardSubtitle,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Form, FormGroup, Label, Input
} from "reactstrap";
import ReactLoading from "react-loading";
import {Formik} from "formik";


const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"))

const Czapkinatki = () => {
    const [caps, setCaps] = useState(null);
    useEffect(() => {
        const fetchProducts = async () => {
            const {mycaps} = await request(
                'https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master', `
                  {
                  mycaps {
                    id
                    title
                    price
                    image {
                      url
                    }
                  }
                } `
            );
            return mycaps
        };
        fetchProducts().then(caps => {
            setCaps(caps)
        });
    }, []);

    const [isAcitve, setIsActive] = useState(false)

    const [activeCap, setActiveCap] = useState(null)

    const openModal = () => setIsActive(true)
    const closeModal = () => setIsActive(false)

    const handleModal = (id) => {
        setActiveCap(caps[id])
        console.log(caps[id])
        setIsActive(true)
    }

    return <>
        <Layout caps={true} className="caps" backgroundColor="white">
            {!isMobile && <AnimatedCursor/>}
            {isAcitve && <Modal isOpen={true}>
                <Row className="p-3 justify-content-between">
                    <ModalHeader> Formularz z zamówieniem </ModalHeader>
                    <ModalHeader onClick={closeModal}> X </ModalHeader>
                </Row>
                {activeCap && <ModalBody>
                    <Card className="border-0 caps">
                        <CardBody>
                            <CardTitle>Produkt: {activeCap.title}</CardTitle>
                            <CardSubtitle>Cena: {activeCap.price} zł</CardSubtitle>
                            <CardTitle>Podaj dane do zamówienia</CardTitle>
                            <Formik
                                initialValues={{name: '', email: '', text: '', number: ''}}
                                onSubmit={(values, {setSubmitting}) => {
                                    const text = `imię: ${values.name} ,uwagi: ${values.text}, numer telefonu:${values.number}
                                    email: ${values.email}`
                                    console.log(text)
                                    var mail = "mailto:" + "czapkinatki@gmail.com" + "?subject=" + "" + "&body=" + text;

                                    const myMail = `mailto:czapkinatki@gmail.com
                                    &subject=czapka zamowienie
                                    &body=${text}`
                                    window = window.open(myMail, 'emailWindow')


                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                  }) => (
                                    <Form id="contact-form" onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Label>Imię</Label>
                                            <Input
                                                type="name"
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Twój Email</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Twój Numer Telefonu</Label>
                                            <Input
                                                type="phone"
                                                name="number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.number}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Uwagi</Label>
                                            <Input
                                                type="textarea"
                                                name="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.text}
                                            />
                                        </FormGroup>
                                        <button type="submit" onClick={() => setTimeout(() => closeModal(), 500)}>
                                            Wyślij
                                        </button>
                                    </Form>

                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </ModalBody>}
            </Modal>}
            <Row>
            {caps && caps.map(({title, image, price}, index) => {
                return <Col
                    sm="3"
                    className="hover-white"
                    style={{margin: ".5rem 0 3rem 0", backgroundColor: "white"}}
                >
                    <Card className="border-0">
                        {image ?
                            <CardImg style={{height: "200px", objectFit: "contain"}} top width="100%"
                                     src={image.url} alt="Card image cap"/> :
                            <ReactLoading type={"bars"} color={"white"} height={667 / 2} width={375 / 2}/>}
                        <CardBody className="category-body">
                            <CardTitle className="category-title">{title}</CardTitle>
                            <CardSubtitle className="text-center">{price} zł</CardSubtitle>
                        </CardBody>
                        <a onClick={() => handleModal(index)} className="buy-now">Kup teraz</a>
                    </Card></Col>
            })}
            </Row>
        </Layout>

    </>
}

export default Czapkinatki